/* Ana Konteyner */
.hakimizda-container {
   margin-top: 20px;
  background: linear-gradient(145deg, #444, #222);
  border-radius: 15px;
  padding: 30px;
  color: #ffffff;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5), -5px -5px 15px rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}


.hakimizda-content {
 padding: 50px;
  display: flex;
  flex-wrap: wrap; 
  gap: 20px;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}


.hakimizda-image {
  flex: 1;
  min-width: 300px; 
  max-width: 600px; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.hakimizda-image img {
  width: 100%; 
  height: auto; 
  max-height: 400px; 
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
}

.hakimizda-image img:hover {
  transform: scale(1.05);
}


.hakimizda-text {
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.hakimizda-text h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color:  #ffcc00  !important;
}

.hakimizda-text p {
  text-align: justify;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 15px;
}


.custom-link {
  text-align: center;
  display: inline-block;
  padding: 10px 20px;
  background-color: #ffcc00;
  color: #333;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 25px;
  transition: all 0.3s ease-in-out;
}

.custom-link:hover {
  color: #fff;
  transform: scale(1.01);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}


/* Responsive Tasarım */
@media only screen and (max-width: 768px) {
  .hakimizda-content {
    flex-direction: column;
    text-align: center; 
  }

  .hakimizda-image {
    width: 100%;
  }

  .hakimizda-image img {
    max-width: 100%; 
    height: auto; 
  }
}
