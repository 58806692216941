/* Üstteki başlık resmi */

.partners-container {
  max-width: 1400px;
  background: linear-gradient(145deg, #444, #222);
  margin: 120px auto 50px auto;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
.image-gallery-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
.image-gallery {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  align-items: center;
}
.header-image {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
  text-align: center;
}

.header-image h1 {
  font-size: 4rem;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
}

.image-item {
  text-align: center;
  width: 100%;
  max-width: 300px;
  aspect-ratio: 3 / 4;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.image-item img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.5s ease;
}
.image-item img:hover {
  transform: scale(1.05);
  transition: ease 0.5s;
}
.link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.image-item p {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  margin: 0;
  padding: 10px;
  background-color: #fff;
  color: #333;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  transition: bottom 0.3s ease, opacity 0.3s ease; 
  opacity: 0; 
}

.image-item:hover p {
  bottom: 0;
  opacity: 1; 
}