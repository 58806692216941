.ceramic-animation {
  position: relative;
  width: 100%;
  height: 80vh;
  perspective: 1000px;
  background: linear-gradient(145deg, #444, #222);
  border-radius: 10px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
  margin-top: 70px;
}

.circle {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  animation: rotate-elipse 10s linear infinite;
}

.ceramic-tile {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 160px;
  transform-origin: center;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.ceramic-tile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}


@keyframes rotate-elipse {
  from {
    transform: rotateY(0deg) rotateX(10deg);
  }
  to {
    transform: rotateY(360deg) rotateX(10deg);
  }
}

/* Responsive Tasarım */
@media (max-width: 768px) {
  .ceramic-animation {
    display : none;
  }

  .ceramic-tile {
    width: 50px;
    height: 100px;
  }
}
