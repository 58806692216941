@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
p,
div {
  font-family: "Merriweather", serif;
  font-style: normal;
}
