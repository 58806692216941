.bathroom {
  position: relative;
  max-width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: cover;
  margin-top: 50px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}

.bathroom img {
  aspect-ratio: 16/7;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.bathroom button {
  position: absolute;
  padding: 10px 20px;
  color: #333;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  cursor: pointer;
  border: none;
  border-radius: 5px;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}
.bathroom button:hover {
  background: linear-gradient(145deg, #666, #444);
  color: #fff; /* Altın rengi */
  transition: all 0.7s ease-in-out;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.5);
}
/* Karartma efekti için overlay */
.bathroom::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 1;
}

/* Ortalanmış metin */
.bathroom::after {
  content: "İhtiyaçlarınıza En Uygun Çözümleri Mağazamızda Keşfedin ve Kaliteyi Yakından Deneyimleyin."; /* Beyaz metin buraya eklenebilir */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  z-index: 1;
}

/* Responsive uyum */
@media (min-width: 350px) and (max-width: 576px){
  .bathroom {
    margin-top: 25px;
  }
  .bathroom::after {
    font-size: 10px;
  }
  .bathroom button {
    padding: 3px 6px;
    top: 75%;
    left: 50%;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .bathroom {
    margin-top: 25px;
  }
  .bathroom::after {
    font-size: 12px;
  }
  .bathroom button {
    padding: 4px 8px;
    top: 70%;
    left: 50%;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .bathroom::after {
    font-size: 18px;
  }
  .bathroom button {
    top: 65%;
    left: 50%;
  }
}
@media (min-width: 992px) and (max-width: 1220px) {
  .bathroom::after {
    font-size: 1.2rem;
  }
}
