/* Ana container */
.main-container {
  margin-bottom: 80px;
  overflow-x: hidden;
}
.fixed-top {
  width: 100%;
  max-width: 100%;
  height: 100px; 
  background: linear-gradient(145deg, #444, #222);

  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
  margin: 0;
  flex-grow: 1; 
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.navbar-toggler {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem; 
  background: none; 
  border: none; 
  cursor: pointer;
}

/* Navbar genişliği ve konumlandırması */
.navClass {
  padding: 0 15px; 
  width: 96%;
  box-sizing: border-box;
}

.navbar-collapse {
  width: 100%;
  overflow-x: hidden;
}
/* Navbar Brand */
.navbar-brand {
  font-family: "Merriweather", serif;
  padding: 10px;
  color: #fff !important;
  font-size: 2rem !important;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
}

/* Navbar linkler */
.nav-links {
  display: flex;
  align-items: center;
  flex-wrap: wrap; 
  justify-content: flex-end;
  gap: 10px;
  font-size: 1rem;
  color: #333; 
}

/* Span etiketi içindeki metin */

.nav-links span {
  padding: 0 5px;
  color: #fff;
  cursor: pointer;
  position: relative;
  transition: color 0.5s ease, text-shadow 0.5s ease;
}

.nav-links span:hover {
 color: #fff;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); 
}
.nav-links span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -4px; 
  width: 0; 
  height: 2px; 
  background-color: #fff;
  transition: width 0.5s ease; 
}
.nav-links span:hover::after {
  width: 100%; 
}

@media (max-width: 768px) {
  .fixed-top {
    height: auto;
    flex-direction: row;
  }

  .navbar-brand {
    font-size: 1rem !important;
    font-weight: 500;
    flex-grow: 1;
    text-align: left;
  }

  .nav-links {
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar-toggler {
    margin-left:auto;
    text-align: right;
    border-color: #fff !important;
  }
  .navbar-toggler-icon {
    background-color: white; /* Arka plan rengini beyaz yapar */
    border-radius: 3px; /* Kenarlarını hafif yuvarlak yapar */
  }
  .nav-link {
    width: 100%;
    text-align: left;
  }

  .nav-link span {
    font-size: 0.9rem;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .fixed-top {
    height: auto;
    padding: 10px;
  }
  .nav-links {
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 15px;
  }

  .nav-link {
    font-size: 1rem;
  }

  .navbar-brand {
    font-size: 1.3rem;
  }
}
