.references-container {
    max-width: 1400px;
    margin: 120px auto 50px auto;
    padding: 20px;
    background: linear-gradient(145deg, #444, #222);
    color: #f1f1f1;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  
  .references-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .references-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .reference-card {
    background: #333;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
  }
  
  .reference-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
  }
  
  .reference-icon {
    font-size: 2rem;
    color: #ffcc00;
    margin-bottom: 10px;
  }
  
  .reference-name {
    font-size: 1rem;
    font-weight: bold;
    color: #f1f1f1;
  }
  
  @media (max-width: 768px) {
    .references-container {
      margin:50px 0 50px 0 ;
    }
    .references-grid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  
    .reference-card {
      padding: 15px;
    }
  
    .reference-icon {
      font-size: 1.5rem;
    }
  
    .reference-name {
      font-size: 0.9rem;
    }
  }
  