.parent-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 100vh;
  gap: 20px;
  padding: 20px;
  background: linear-gradient(120deg, #333, #111);
}

@media (max-width: 768px) {
  .parent-container {
    flex-direction: column;
    height: auto;
  }
}

  
  